import { Component, OnInit } from '@angular/core';
  
@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-OrderDetail.component.html'
})
export class ModalContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  } 


}
