import { Component } from '@angular/core';
import { ConfirmationDialogService } from './admin/settings/confirm/confirmation-dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
 

}
